













import { Component, Vue, Watch } from 'vue-property-decorator';
import { wordpressPageModule } from '@/store/modules/wordpress-page';
import { AppActionTypes } from '@/store/types';

@Component
export default class HelpCenter extends Vue {
    src = '';

    @Watch('$route.params.url', { immediate: true })
    renderHtml(url: string) {
        this.src = '';
        const pageSelected = wordpressPageModule.pages.find(
            ({ slug }) => slug === url
        );
        if (pageSelected) {
            this.$store.dispatch(AppActionTypes.showLoadingAction);
            this.src = pageSelected.link;
        }
    }

    load() {
        this.$store.dispatch(AppActionTypes.hideLoadingAction);
    }
}
